import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Front-end development services`}</h1>
    <p>{`I offer responsive front-end development services for projects in:`}</p>
    <ul>
  <li>ReactJs</li>
  <li>NextJs</li>
  <li>GatsbyJs</li>
    </ul>
    <h3>{`Pricing`}</h3>
    <ul>
  <li>$280/page.</li>
    </ul>
    <h3>{`What do I need from you?`}</h3>
    <ul>
  <li>Your designs.</li>
  <li>They can be in Figma, Sketch, Adobe Xd.</li>
    </ul>
    <h3>{`Delivery`}</h3>
    <ul>
  <li>The average turnaround time is 3 working days per page.</li>
  <li>You get the code either as a Zip file sent by email or as access to a Git repository.</li>
    </ul>
    <p>{`You can reach me at `}<a href="https://www.linkedin.com/in/mathengedennis" target="_blank">{`Dennis`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      