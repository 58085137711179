import React from 'react';
import styled from "styled-components";
import Layout from '../components/Layout';
import SEO from "../components/Seo";

function MarkdownPage({children, pageContext: {frontmatter}}) {
  return (
    <>
      <SEO title={frontmatter.title} />
      <Layout>
        <Container>
          {children}
        </Container>
      </Layout>
    </>
  )
}

//Component styles
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  h1 {
    margin: 20px 0;
  }
  h2, h3 {
    margin-bottom: 0.3rem;
  }
  p {
    margin: 0.8rem 0;
    line-height: 1.7;
  }
  a {
    font-weight: 700;
    text-decoration: underline;
  }
  ul {
    margin: 0.8rem 0;
    padding-left: 2rem;
  }
  li {
    line-height: 1.5;
    list-style-type: disc;
  }
`;

export default MarkdownPage;